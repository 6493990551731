export class Header extends HTMLElement {
  private isOpen: boolean;

  public constructor() {
    super();
    this.isOpen = false;
  }

  private effect(): void {
    ['.overlay', '.menuIcon'].forEach(selector => {
      if (this.isOpen) this.querySelector(selector)?.classList.add('open');
      else this.querySelector(selector)?.classList.remove('open');
    });
  }

  public connectedCallback(): void {
    const handleClick = (): void => {
      this.isOpen = !this.isOpen;
      this.effect();
    };

    const handleClose = (): void => {
      this.isOpen = false;
      this.effect();
    };

    setTimeout(() => {
      this.querySelector('.menuIcon')?.addEventListener('click', handleClick);

      this.querySelectorAll('.menuItem')?.forEach(element =>
        element.addEventListener('click', handleClose)
      );
    }, 0);
  }
}
