export class Slider extends HTMLElement {
  private index: number;

  public constructor() {
    super();
    this.index = 0;
  }

  private effect(): void {
    const change = (selector: string): void => {
      this.querySelectorAll(`${selector}.current`)?.forEach(element =>
        element.classList.remove('current')
      );
      this.querySelectorAll(`${selector}`)[this.index].classList.add('current');
    };
    change('.bgImage');
    change('.paginationBullet');
  }

  public connectedCallback(): void {
    const handleClick = (e: Event): void => {
      if (!(e.target instanceof Element)) return;

      this.index = Number(
        Array.from(this.querySelectorAll('.paginationBullet')).indexOf(e.target)
      );

      this.effect();
    };

    setTimeout(() => {
      this.querySelectorAll('.paginationBullet')?.forEach(element =>
        element.addEventListener('click', handleClick)
      );

      setInterval(() => {
        if (process.env.PUBLIC_APP_ENV === 'test') return;

        const items = document.querySelector('.container')?.children;
        if (items == null) return;

        const imageListLength = items.length - 1;
        this.index = this.index >= imageListLength ? 0 : this.index + 1;

        this.effect();
      }, 4000);
    }, 0);
  }
}
