import SweetScroll from 'sweet-scroll';

export class ToTop extends HTMLElement {
  private scroller: SweetScroll;

  public constructor() {
    super();
    this.scroller = new SweetScroll();
  }

  public connectedCallback(): void {
    const handleClick = (): void => this.scroller.toTop(0, {duration: 300});
    this.addEventListener('click', handleClick);
  }
}
